import React from 'react';
import { Careers } from 'weevaluate-sdk';

function App() {
  return (
    <div className="App">
      <Careers authorizationKey="Q0YsY29kZWZhY3Rzc29sdXRpb25zQGdtYWlsLmNvbSwxMTI4MDY=" />
    </div>
  );
}

export default App;